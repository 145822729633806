
/* ===============Mobile View and Tab View=============== */
@media only screen and (min-width:300px) and (max-width:1024px)
{

    .navbar-collapse {
        position: fixed;
        top: 5.6rem;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: white;
        -webkit-transition: visibility 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        transition: visibility 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        transition: visibility 0.4s ease-in-out, transform 0.4s ease-in-out;
        transition: visibility 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
      }
      
        
    
      .navbar-collapse.show {
        visibility: visible;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
                
      }
      .navbar.sticky-top
      {
        border-radius: 0 !important;
      }
    .schemew {
   
        min-width: 23em!important;
    }
    .min-w-11em
{
    min-width: 11em;
}
.min-w-3em
{
    min-width: 3em; 
}

.min-w-14em
{
    min-width: 14em!important;
}
.min-w-9em
{
    min-width: 9em!important;
}
.min-w-6em
{
    min-width: 6em!important;
}
.min-w-15em
{
    min-width: 15em;
}
.min-w-8em
{
    min-width: 8em;
}
.min-w-10em {
    min-width: 10em;
}
.min-w-35em
{
    min-width: 35em!important;
}
.dropdown-menu
{
    z-index: 999!important;
    position: absolute!important;
}
.ml-sm-auto
{
    margin-left: auto!important;
}
.nfo .nav-pills .nav-link {
    padding: 9px 45px;
}

}
/* ===============Mobile View and Tab View end=============== */

/* ===============Mobile View=============== */
@media only screen and (min-width:300px) and (max-width:600px)
{
    .d-none-m
    {
        display: none;
    }
    .ml-sm-0
    {
        margin-left: 0px !important;
    }
    .family-portfolio .table td, .family-portfolio .table th {
       
        font-size: 10px;
    }
    .mt-sm-2
    {
        margin-top: 1em !important;
    }
    .pt-xs-2
    {
        padding-top: 1em !important;
    }
    .topbar {
        height: auto !important;
    }
    .nav-head .main-menu li a {

        padding: 11px 25px;

    }
    .topbar .topbar-divider
    {
        display: none;
    }
    .mt-sm-3
    {
        margin-top: 1rem!important;
    }
    li.menu_has_children:after
    {
        top: 15px;
    }
    .col-sm-6,.col-sm-4,.col-sm-5,.col-sm-3,.col-sm-12,.col-md-1,.col-sm-7,.col-sm-10
    {
        width: auto;
    }
   .home-main .chartjs-render-monitor {
        width: 296px!important;
        height: 148px!important;
    }
    .quick-card,.text-smc-center
    {
        text-align: center!important;
    }
    .d-sm-inline-flex
    {
        display: inline-flex;
    }
    .longshort.nav-pills .nav-link {
        padding: 9px 18px!important;
    }
    .filter-all-mf .nav-pills .nav-link {
       
        padding: 9px 12px !important;
    }
    
    .invest-q.modal {
        top: 0em!important;
        left: 0em!important;
    }
.ryd .btn-custom,.tax-p .nav-pills .nav-link
{
    padding: 10px 33px !important;
}
.r-form .new-btn1,.adv-l .nav-pills .nav-link ,.btn-custom
   
{
    padding: 10px 26px !important;
    font-size: 12px!important;
}

.r-form .shadow-custom,.m-sm-auto
{
  margin: auto!important;  
}
.r-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

    padding: 16.5px 46px!important;  
}
.r-form .para {
    font-size: 13px;
}
#progressbar li 
{
    font-size: 8px!important; 
}
.r-form h2.text-success,.r-form h6.text-success
{
    font-size: 16px;  
}
.fs-sm-10
{
    font-size: 10px!important;
}
.my-sm-3
{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.pl-sm-4em
{
padding-left: 4em;
}
.pl-sm-7em
{
padding-left: 7em;
}

.p-sm-3
{
    padding: 1.5em;
}
.text-sm-center
{
    text-align: center!important;
}
.result-content-shadow
{
margin-right: -18px;
}
.px-sm-3
{
    padding-left: 1em!important;
    padding-right: 1em!important;
}
.profile .form-control
{
    font-size: 13px;
}
.fs-sm-13
{
    font-size: 13px!important;
}
  
}

/* ===============Mobile View end=============== */

/* ===============Tab View=============== */
@media only screen and (min-width:700px) and (max-width:1024px)
{
    .nav-head .main-menu li a {

        padding: 11px 25px;

    }
    .topbar {
        height: auto !important;
    }
    .topbar .topbar-divider
    {
        display: none;
    }
    .fs-sm-10
{
    font-size: 10px!important;
}

.mt-input {
    margin-top: 5.5%!important;
}
    .pt-tab-2
    {
        padding-top: 1em !important;
    }
    .calculator .text-label {
        font-size: 12px !important;
    }
   .tax-p .nav-pills .nav-link {
        padding: 9px 47px;
    }
    .filter-all-mf .nav-pills .nav-link {
      
        padding: 9px 27px !important;
    }
    
    .longshort.nav-pills .nav-link {
        padding: 9px 29px;
    }
    .quick-card {       
        padding: 0.75rem 0.5rem;
    }
    .invest-q.modal {
        top: auto!important;
        left: auto!important;
    }
    .goal-plan .col-m {
        margin: auto 33px!important;
    }
    .adv-l .nav-pills .nav-link {
        padding: 9px 40px;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 10.5px 14px!important;
    }

}
/* ===============Tab View end=============== */